import React from "react";
import { Spinner } from "react-bootstrap";
import BackgroundBlur from "../sub-components/Blur";

export default function FullPageLoading(props) {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        minHeight: props.height || "66vh",
        ...props.style,
      }}
    >
      <span
        className={!props.smallLoader ? "full-loader" : "small-loader"}
        style={{
          ...props.innerLoaderStyle,
        }}
      ></span>
      <BackgroundBlur loader backgroundColor="#f8f9fa" />
    </div>
  );
}
