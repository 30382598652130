import React, { useState, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarGraph from "../../../components/Analytics/BarGraph";
import { secure_instance } from "../../../axios/axios-config";
import { generateGraphQuery } from "../../../utilities/CubeUtils";
import { getGraphLoader } from "../../../utilities/Loaders";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import {
  capitalizeFirstAndRemoveLastTwoLetters,
  capitalizeFirstLetter,
  getGraphGranularityMappings,
} from "../../../utilities/Utils";
import { triggerProductivityGraphReFetch } from "../../../redux/slices/dashboardSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import CustomSelect from "../../../components/sub-components/Select";

const AnalyticsCardNew = (props) => {
  const {
    usersData,
    // graphDataTemp,
    globalSelectedFilter,
    globalSelectedCategory,
    graphName,
    isRefreshAnalytics,
    indexBy,
    graphType,
    hideDateOnToolTip,
    isTeamSelected,
    userProfilesExtracted,
    organizationItemSelected,
  } = props;

  const isTriggerRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerRefetch,
  );
  const isTriggerProductivityGraphRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerProductivityGraphRefetch,
  );
  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );
  const user = useSelector((state) => state.authReducer.user);

  const dispatch = useDispatch();

  const [menuItemSelected, setMenuItemSelected] = useState([]);
  const [userSelected, setUserSelected] = useState({});
  const [userApplied, setUserApplied] = useState({});
  const [selectedGranularity, setSelectedGranularity] = useState("daily");
  const [selectedGroupByItem, setSelectedGroupByItem] = useState(
    graphType === "eventTypeGraph" ? "source" : "none",
  );

  const usersAnchorEl = React.useRef(null);
  const [isUsersMenuOpen, setIsUsersMenuOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const granularityAnchorEl = React.useRef(null);
  const [isGranularityMenuOpen, setIsGranularityMenuOpen] =
    React.useState(false);

  const groupByAnchorEl = React.useRef(null);
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = React.useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [groupByGraphKeys, setGroupByGraphKeys] = useState("none");
  const [graphUserFilters, setGraphUserFilters] = useState(
    organizationGlobalFilter,
  );
  // const [teamDataForMapping, setTeamDataForMapping] = useState([]);

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  const rearrangeGraphDataForGroupBy = async (
    data,
    selectedGroupByItem,
    indexByKey,
  ) => {
    return new Promise((resolve, reject) => {
      const worker = new window.Worker("./cube-worker.js");
      worker.postMessage({
        message: "REARRANGE_GROUPBY_DATA",
        data: {
          data,
          selectedGroupByItem,
          indexByKey,
        },
      });
      worker.onerror = (err) => {
        console.log("~web worker error", err);
      };
      worker.onmessage = (e) => {
        const { message, result, error } = e.data;
        if (!error && message === "REARRANGE_GROUPBY_DATA_RESULT") {
          resolve({ uniqueKeys: result.uniqueKeys, groups: result.groups });
          // return { uniqueKeys: result.uniqueKeys, groups: result.groups };
        }
        worker.terminate();
      };
    });
  };

  const handleApplyUserFilter = (item) => {
    if (item === userSelected) {
      setUserSelected({});
    } else {
      setUserSelected(item);
    }
    handleGenericMenuClose(setIsUsersMenuOpen);
    setUserApplied(item);

    if (Object.keys(item).length === 0) {
      if (Object.keys(organizationItemSelected).length === 0) {
        // userFilters.filters = [];
        setGraphUserFilters((prevState) => ({
          ...prevState,
          filters: [],
        }));
      } else if (organizationItemSelected) {
        // userFilters.filters = [
        //   {
        //     col: "group_id",
        //     operator: "==",
        //     value: organizationItemSelected.group_id,
        //   },
        // ];
        setGraphUserFilters((prevState) => ({
          ...prevState,
          filters: [
            {
              col: "group_id",
              operator: "==",
              value: organizationItemSelected.group_id,
            },
          ],
        }));
      }
    } else if (Object.keys(item).length > 0) {
      // userFilters.filters = [
      // {
      //   col: "user_id",
      //   operator: "==",
      //   value: userSelected.user_id,
      // },
      // ];
      setGraphUserFilters((prevState) => ({
        ...prevState,
        filters: [
          {
            col: "user_id",
            operator: "==",
            value: item.user_id,
          },
        ],
      }));
    }

    dispatch(triggerProductivityGraphReFetch(true));
    setTimeout(() => {
      dispatch(triggerProductivityGraphReFetch(false));
    }, 1000);
  };

  const handleCancelUserFilter = () => {
    handleGenericMenuClose(setIsUsersMenuOpen);
    setUserSelected(userApplied);
  };

  const handleGraphDataFetch = async (
    query,
    groupByItem,
    selectedGranularity,
  ) => {
    setIsGraphLoading(true);
    // let modifiedQuery = { ...query }; // Start with a copy of the original query

    // if (groupByItem === "teams") {
    //   modifiedQuery = {
    //     ...query,
    //     fetch: {
    //       model: "Team",
    //       filters: [
    //         {
    //           col: "tenant_id",
    //           operator: "==",
    //           value: user.tenantId,
    //         },
    //       ],
    //     },
    //   };
    // }
    const response = await getGraphDataWithChMaestro(query);
    const indexByForRearrangeFunction =
      getGraphGranularityMappings(selectedGranularity);

    if (!response?.graph) return;

    // const { graph, addition_data } = response;
    const { graph } = response;
    if (groupByItem === "none") {
      setGraphData(graph);
      setGroupByGraphKeys([]);
    } else {
      const { uniqueKeys, groups } = await rearrangeGraphDataForGroupBy(
        // groupByItem === "teams" ? graph[0].data : graph,
        graph,
        groupByItem,
        indexByForRearrangeFunction,
      );
      setGroupByGraphKeys([...new Set(uniqueKeys)]);
      setGraphData(groups);
      // setTeamDataForMapping(addition_data);
    }

    setIsGraphLoading(false);
  };

  // A better option would be to set Granularity as .value, instead of accessing .value everytime?

  const handleGroupByOptionSelected = async (e) => {
    setSelectedGroupByItem(e.target.value);
    handleGenericMenuClose(setIsGroupByMenuOpen);
    if (e.target.id === "teams") {
      // const updatedGraphUserFilters =
      //   e.target.id === "teams"
      //     ? { ...graphUserFilters, group: ["group_id"] }
      //     : { ...graphUserFilters, group: [] };
      const query = generateGraphQuery(
        dashboardGlobalFilters,
        graphUserFilters,
        "none",
        getGraphGranularityMappings(selectedGranularity),
      );

      handleGraphDataFetch(query, e.target.id, selectedGranularity);
      return;
    }
    const query = generateGraphQuery(
      dashboardGlobalFilters,
      graphUserFilters,
      e.target.value,
      getGraphGranularityMappings(selectedGranularity),
    );

    handleGraphDataFetch(query, e.target.value, selectedGranularity);
  };

  const handleGranularityOptionSelected = async (e) => {
    setSelectedGranularity(e.target.value);
    console.log("eee", e);
    handleGenericMenuClose(setIsGranularityMenuOpen);

    const query = generateGraphQuery(
      dashboardGlobalFilters,
      graphUserFilters,
      selectedGroupByItem,
      getGraphGranularityMappings(e.target.value),
    );

    handleGraphDataFetch(query, selectedGroupByItem, e.target.value);
  };

  const getGraphDataWithChMaestro = async (query) => {
    try {
      const request = await secure_instance.request({
        url: "v1/graph/graphs",
        method: "Post",
        data: query,
      });
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  useMemo(async () => {
    const query = generateGraphQuery(
      dashboardGlobalFilters,
      graphUserFilters,
      selectedGroupByItem,
      getGraphGranularityMappings(selectedGranularity),
    );

    handleGraphDataFetch(query, selectedGroupByItem, selectedGranularity);
  }, []);

  useMemo(async () => {
    if (isTriggerProductivityGraphRefetch || isTriggerRefetch) {
      const query = generateGraphQuery(
        dashboardGlobalFilters,
        graphUserFilters,
        selectedGroupByItem,
        getGraphGranularityMappings(selectedGranularity),
      );

      handleGraphDataFetch(query, selectedGroupByItem, selectedGranularity);
    }
  }, [isTriggerProductivityGraphRefetch, isTriggerRefetch]);

  return (
    <div className="w-100" style={{ padding: "32px 0" }}>
      <div
        style={{
          height: "420px",
          border: "1px solid #C6C6C6",
          borderRadius: "20px",
          background: "#FFFFFF",
          boxShadow: "2px 2px 15px 0px #E5D5F2",
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "24px 32px 0 32px",
          }}
        >
          <div className="s1 d-flex align-items-center">{graphName}</div>

          <div className="d-flex align-items-center">
            <CustomSelect
              items={["None", "Users", "Event Types", "Sources", "Teams"].map(
                (item) => ({
                  value: item.toLowerCase(),
                  label: item,
                }),
              )}
              value={selectedGroupByItem}
              onChange={handleGroupByOptionSelected}
              useCustomMenu
              customSelectPlaceholder={
                selectedGroupByItem === "none"
                  ? "Group By"
                  : capitalizeFirstLetter(selectedGroupByItem)
              }
              // menuItems={["None", "Users", "Event Types", "Sources", "Teams"]}
              menuItems={["None", "Users", "Event Types", "Sources"]}
              handleOptionSelected={handleGroupByOptionSelected}
              activeMenuItem={selectedGroupByItem}
            />

            <div className="mr-3" />

            <CustomSelect
              items={["Hourly", "Daily", "Weekly", "Monthly", "Yearly"].map(
                (item) => ({
                  value: item.toLowerCase(),
                  label: item,
                }),
              )}
              value={selectedGranularity}
              onChange={handleGranularityOptionSelected}
              customSelectPlaceholder={capitalizeFirstLetter(
                selectedGranularity.label,
              )}
              useCustomMenu
            />

            {isTeamSelected && (
              <>
                <div className="mr-3" />

                <PopperDropdown
                  anchorRef={usersAnchorEl}
                  isDropdownOpen={isUsersMenuOpen}
                  handleToggle={() => handleGenericMenuOpen(setIsUsersMenuOpen)}
                  handleClose={() => handleCancelUserFilter()}
                  dropdownDisplayText="Users"
                  dropdownWithSearchAndCheckbox
                  dropdownData={userProfilesExtracted}
                  handleApply={handleApplyUserFilter}
                  handleCancel={handleCancelUserFilter}
                  dropdownItemsSelected={userSelected}
                />
              </>
            )}
          </div>
        </div>

        {isGraphLoading ? (
          <div
            style={{
              minWidth: "95%",
              paddingLeft: "30px",
              height: "398px",
              marginTop: "34px",
              maxHeight: "fit-content",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{
                position: "relative",
                margin: "0 auto",
                width: "100%",
                height: "100%",
              }}
            >
              {/* {getGraphLoader()} */}
              <div style={{ position: "absolute", left: "43%", top: "25%" }}>
                <div className="h5 grey9 mt-3">Loading Data</div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              minWidth: "95%",
              maxWidth: "99%",
              paddingLeft: "30px",
              marginTop: "50px",
            }}
          >
            <BarGraph
              // startDate={startDate}
              // endDate={endDate}
              // dateChanged={dateChanged}
              // setDateChanged={setDateChanged}
              selectedGranularity={selectedGranularity}
              // groupByGraphKeys={groupByGraphKeys}
              usersData={usersData}
              graphContainerHeight={"380px"}
              selectedGroupByItem={selectedGroupByItem}
              graphData={graphData}
              // teamDataForMapping={teamDataForMapping}
              // graphData={ffff}
              currentFilters={[]}
              memberSelected={menuItemSelected}
              globalSelectedCategory={globalSelectedCategory}
              isRefreshAnalytics={isRefreshAnalytics}
              globalSelectedFilter={globalSelectedFilter}
              // graphName={graphName}
              indexBy={getGraphGranularityMappings(selectedGranularity)}
              groupByGraphKeys={groupByGraphKeys}
              // tooltip={null}
              // tooltip={(input) => {
              //   return input;
              // }}
              hideDateOnToolTip={hideDateOnToolTip}
              // Top10PerformersGraph={Top10PerformersGraph}
              // matchingUsersInGraphData={matchingUsersInGraphData}

              padding={0.09}
              // colors={"#A790FF"}
              // isAxisBottomDisplay={false}
              // isAxisLeftDisplay={false}
              // enableGridX={false}
              // enableGridY={false}
              borderRadius={10}
              useCanvas={true}
              // margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            />
          </div>
        )}
      </div>
      {/* </div > */}
    </div>
  );
};

export default memo(AnalyticsCardNew);
