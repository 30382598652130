import React from "react";
import { capitalizeFirstLetter, getRandomColor } from "../../utilities/Utils";
import DelayedSkeletonLoader from "../sub-components/DelayedSkeletonLoader";
import { GetGraphLoader } from "../../utilities/Loaders";
import FullPageLoading from "../Loading/FullPageLoading";

const DashboardOverviewGraphs = ({
  icon,
  graphType,
  quantifyText,
  time,
  bottomText,
  graph,
  pieChart,
  isLoading,
  loaderComponent,
  colors,
}) => {
  return (
    <div className="key-result-cards-container" style={{ maxHeight: "210px" }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {icon}

          <div className="d-flex flex-column justify-content-between ml-3">
            <span className="d-flex head6-medium">{graphType}</span>
            <span
              className="d-flex caption1 grey6"
              style={{ whiteSpace: "pre" }}
            >
              {isLoading ? (
                <DelayedSkeletonLoader type="text-sm" />
              ) : (
                quantifyText
              )}
            </span>
          </div>
        </div>

        <div className="caption1 grey6">{time}</div>
      </div>
      <div className="row">
        <div className="body2 grey8 col-lg-6 d-flex align-items-end">
          {pieChart ? (
            <div className="ml-1 d-flex flex-wrap">
              {bottomText?.map((text, index) => {
                return (
                  <div
                    //
                    className="d-flex align-items-center"
                    key={index}
                    style={{ minWidth: "60px", marginRight: "8px" }}
                  >
                    <span
                      className="d-flex align-items-center"
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "20px",
                        background: colors[index],
                        // background: "black",
                        marginRight: "6px",
                      }}
                    />
                    {capitalizeFirstLetter(text)}
                  </div>
                );
              })}
            </div>
          ) : (
            bottomText
          )}
        </div>
        <div className="col-lg-6">
          {isLoading ? (
            <div
              style={{
                minWidth: "100%",
              }}
            >
              <div>
                {/* <GetGraphLoader
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  isOverviewGraph={true}
                /> */}
                {loaderComponent ?? null}
                <div
                  style={{
                    position: "absolute",
                    left: "44%",
                    top: "35%",
                  }}
                >
                  <FullPageLoading height="50%" smallLoader={true} />
                </div>
              </div>
            </div>
          ) : (
            graph
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardOverviewGraphs;
