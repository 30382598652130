export const lineGraphData = [
  {
    id: "japan",
    color: "hsl(22, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 250,
      },
      {
        x: "helicopter",
        y: 195,
      },
      {
        x: "boat",
        y: 189,
      },
      {
        x: "train",
        y: 203,
      },
      {
        x: "subway",
        y: 133,
      },
      {
        x: "bus",
        y: 243,
      },
      {
        x: "car",
        y: 171,
      },
      {
        x: "moto",
        y: 140,
      },
      {
        x: "bicycle",
        y: 74,
      },
      {
        x: "horse",
        y: 88,
      },
      {
        x: "skateboard",
        y: 265,
      },
      {
        x: "others",
        y: 112,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(63, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 213,
      },
      {
        x: "helicopter",
        y: 41,
      },
      {
        x: "boat",
        y: 151,
      },
      {
        x: "train",
        y: 286,
      },
      {
        x: "subway",
        y: 39,
      },
      {
        x: "bus",
        y: 221,
      },
      {
        x: "car",
        y: 141,
      },
      {
        x: "moto",
        y: 128,
      },
      {
        x: "bicycle",
        y: 269,
      },
      {
        x: "horse",
        y: 210,
      },
      {
        x: "skateboard",
        y: 25,
      },
      {
        x: "others",
        y: 141,
      },
    ],
  },
  {
    id: "us",
    color: "hsl(158, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 295,
      },
      {
        x: "helicopter",
        y: 192,
      },
      {
        x: "boat",
        y: 277,
      },
      {
        x: "train",
        y: 91,
      },
      {
        x: "subway",
        y: 4,
      },
      {
        x: "bus",
        y: 141,
      },
      {
        x: "car",
        y: 95,
      },
      {
        x: "moto",
        y: 58,
      },
      {
        x: "bicycle",
        y: 182,
      },
      {
        x: "horse",
        y: 51,
      },
      {
        x: "skateboard",
        y: 271,
      },
      {
        x: "others",
        y: 215,
      },
    ],
  },
  {
    id: "germany",
    color: "hsl(250, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 74,
      },
      {
        x: "helicopter",
        y: 282,
      },
      {
        x: "boat",
        y: 62,
      },
      {
        x: "train",
        y: 48,
      },
      {
        x: "subway",
        y: 100,
      },
      {
        x: "bus",
        y: 75,
      },
      {
        x: "car",
        y: 82,
      },
      {
        x: "moto",
        y: 194,
      },
      {
        x: "bicycle",
        y: 87,
      },
      {
        x: "horse",
        y: 105,
      },
      {
        x: "skateboard",
        y: 104,
      },
      {
        x: "others",
        y: 101,
      },
    ],
  },
  {
    id: "norway",
    color: "hsl(91, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 99,
      },
      {
        x: "helicopter",
        y: 151,
      },
      {
        x: "boat",
        y: 257,
      },
      {
        x: "train",
        y: 169,
      },
      {
        x: "subway",
        y: 65,
      },
      {
        x: "bus",
        y: 162,
      },
      {
        x: "car",
        y: 74,
      },
      {
        x: "moto",
        y: 199,
      },
      {
        x: "bicycle",
        y: 17,
      },
      {
        x: "horse",
        y: 184,
      },
      {
        x: "skateboard",
        y: 276,
      },
      {
        x: "others",
        y: 293,
      },
    ],
  },
];

export const lineGraphDataSingle = [
  {
    id: "japan",
    color: "#D7CCFF",
    data: [
      {
        x: "plane",
        y: 100,
      },
      {
        x: "helicopter",
        y: 150,
      },
      {
        x: "boat",
        y: 200,
      },
      {
        x: "train",
        y: 210,
      },
      {
        x: "subway",
        y: 160,
      },
      {
        x: "bus",
        y: 230,
      },
      {
        x: "car",
        y: 191,
      },
      {
        x: "moto",
        y: 140,
      },
      {
        x: "bicycle",
        y: 200,
      },
      {
        x: "horse",
        y: 230,
      },
      {
        x: "skateboard",
        y: 245,
      },
      {
        x: "others",
        y: 300,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(94, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 105,
      },
      {
        x: "helicopter",
        y: 28,
      },
      {
        x: "boat",
        y: 280,
      },
      {
        x: "train",
        y: 85,
      },
      {
        x: "subway",
        y: 88,
      },
      {
        x: "bus",
        y: 97,
      },
      {
        x: "car",
        y: 218,
      },
      {
        x: "moto",
        y: 173,
      },
      {
        x: "bicycle",
        y: 176,
      },
      {
        x: "horse",
        y: 20,
      },
      {
        x: "skateboard",
        y: 76,
      },
      {
        x: "others",
        y: 28,
      },
    ],
  },
  {
    id: "us",
    color: "hsl(250, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 70,
      },
      {
        x: "helicopter",
        y: 221,
      },
      {
        x: "boat",
        y: 243,
      },
      {
        x: "train",
        y: 284,
      },
      {
        x: "subway",
        y: 58,
      },
      {
        x: "bus",
        y: 160,
      },
      {
        x: "car",
        y: 84,
      },
      {
        x: "moto",
        y: 237,
      },
      {
        x: "bicycle",
        y: 251,
      },
      {
        x: "horse",
        y: 87,
      },
      {
        x: "skateboard",
        y: 288,
      },
      {
        x: "others",
        y: 15,
      },
    ],
  },
];
export const lineGraphDataDouble = [
  {
    id: "japan",
    color: "#D7CCFF",
    data: [
      {
        x: "plane222",
        y: 100,
      },
      {
        x: "helicopter222",
        y: 150,
      },
      {
        x: "boat222",
        y: 200,
      },
      {
        x: "train222",
        y: 210,
      },
      {
        x: "subway222",
        y: 160,
      },
      {
        x: "bus222",
        y: 230,
      },
      {
        x: "car222",
        y: 191,
      },
      {
        x: "moto222",
        y: 140,
      },
      {
        x: "bicycle222",
        y: 200,
      },
      {
        x: "horse222",
        y: 230,
      },
      {
        x: "skateboard222",
        y: 245,
      },
      {
        x: "others222",
        y: 300,
      },
    ],
  },
  {
    id: "pakistan",
    color: "#D7CCFF",
    data: [
      {
        x: "plane222",
        y: 90,
      },
      {
        x: "helicopter222",
        y: 100,
      },
      {
        x: "boat222",
        y: 150,
      },
      {
        x: "train222",
        y: 170,
      },
      {
        x: "subway222",
        y: 60,
      },
      {
        x: "bus222",
        y: 90,
      },
      {
        x: "car222",
        y: 141,
      },
      {
        x: "moto222",
        y: 110,
      },
      {
        x: "bicycle222",
        y: 200,
      },
      {
        x: "horse222",
        y: 230,
      },
      {
        x: "skateboard222",
        y: 245,
      },
      {
        x: "others222",
        y: 300,
      },
    ],
  },
];
export const lineGraphDataDoubleTest = [
  {
    status: 200,
    id: "communication",
    data: [
      {
        event_type: "communication",
        user_id: 95,
        y: 2,
        x: "2023-11-21",
      },
      {
        event_type: "communication",
        user_id: 107,
        y: 9,
        x: "2023-11-20",
      },
      {
        event_type: "communication",
        user_id: 101,
        y: 3,
        x: "2023-11-21",
      },
      {
        event_type: "communication",
        user_id: 95,
        y: 5,
        x: "2023-11-15",
      },
    ],
  },
  {
    status: 200,
    id: "code",
    data: [
      {
        event_type: "communication",
        user_id: 95,
        y: 2,
        x: "2023-11-21",
      },
      {
        event_type: "communication",
        user_id: 107,
        y: 9,
        x: "2023-11-20",
      },
      {
        event_type: "communication",
        user_id: 101,
        y: 3,
        x: "2023-11-21",
      },
      {
        event_type: "communication",
        user_id: 95,
        y: 5,
        x: "2023-11-15",
      },
    ],
  },
];

export const pieChartDummyData = [
  {
    id: "hack",
    label: "hack",
    value: 63,
    color: "hsl(197, 70%, 50%)",
  },
  {
    id: "rust",
    label: "rust",
    value: 374,
    color: "hsl(89, 70%, 50%)",
  },
  {
    id: "java",
    label: "java",
    value: 571,
    color: "hsl(340, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 282,
    color: "hsl(207, 70%, 50%)",
  },
];
