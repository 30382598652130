import { ResponsiveBar, ResponsiveBarCanvas } from "@nivo/bar";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getBarGraphValues,
  getEmojisMapping,
} from "../../utilities/GraphUtils";
import dayjs from "dayjs";
import {
  getFormatForCurrentGranularity,
  getGraphGranularityMappings,
} from "../../utilities/Utils";
// import { addMissingDates } from "../../utilities/dateConversion";

// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered.

const BarGraph = (props) => {
  const {
    graphData,
    usersData,
    groupByGraphKeys,
    currentFilters,
    selectedGroupByItem,
    graphName,
    selectedGranularity,
    matchingUsersInGraphData,
    indexBy,
    hideDateOnToolTip,
    padding,
    colors,
    isAxisBottomDisplay,
    isAxisLeftDisplay,
    enableGridX,
    enableGridY,
    tooltip,
    minValue,
    maxValue,
    borderRadius,
    margin,
    graphContainerHeight,
    mapEmojis,
    useCanvas,
    isInteractive,
    // teamDataForMapping,
  } = props;

  // const [singleUserProfile, setSingleUserProfile] = useState([]);
  const getToolTipNames = (value) => {
    // console.log("valuevalue", value);
    if (selectedGroupByItem === "users") {
      const element = usersData.find((element) => {
        return parseInt(element.user_id) === parseInt(value);
      });
      return element?.name;
    }
    // if (selectedGroupByItem === "teams") {
    //   const element = teamDataForMapping.find((element) => {
    //     return parseInt(element.group_id) === parseInt(value);
    //   });
    //   return element?.name;
    // }
    if (
      selectedGroupByItem === "sources" ||
      selectedGroupByItem === "event types"
    ) {
      return value;
    }
  };

  // --------------------------------- THIS CODE IS ADDING MISSING DATES AND SORTING DATA STARTS ---------------------------------

  // const groupedData = data?.reduce((acc, cur) => {
  // 	const eventTime = cur[`DumpTableMain.eventTime`].split("T")[0];
  // 	// const eventTime = cur["DumpTableMain.eventTime.day"];
  // 	const count = parseInt(cur["DumpTableMain.count"]);
  // 	if (acc[eventTime]) {
  // 		acc[eventTime].count += count;
  // 	} else {
  // 		acc[eventTime] = {
  // 			eventTime,
  // 			count
  // 		};
  // 	}

  // 	return acc;
  // }, {});

  // const result = Object.values(groupedData || {});

  // result?.length > 0 && result.reduce(function (hash) {
  // 	return function (p, c) {
  // 		const missingDaysNo = (Date.parse(c.eventTime) - hash.prev) / (1000 * 3600 * 24);
  // 		if (hash.prev && missingDaysNo > 1) {
  // 			for (let i = 1; i < missingDaysNo; i++) {
  // 				const missing = (new Date(hash.prev + i * (1000 * 3600 * 24)));
  // 				const date = new Date(missing);
  // 				const year = date.getFullYear();
  // 				const month = String(date.getMonth() + 1).padStart(2, "0");
  // 				const day = String(date.getDate()).padStart(2, "0");

  // 				const formattedDate = `${year}-${month}-${day}`;
  // 				p.push(formattedDate);
  // 				const newObj = {
  // 					eventTime: formattedDate,
  // 					count: 0
  // 				};
  // 				result.push(newObj);
  // 			}
  // 		}
  // 		hash.prev = Date.parse(c.eventTime);
  // 		return p;
  // 	};
  // }(Object.create(null)), []);

  // result.sort(function (a, b) {
  // 	if (a.eventTime < b.eventTime) {
  // 		return -1;
  // 	}
  // 	if (a.eventTime > b.eventTime) {
  // 		return 1;
  // 	}
  // 	return 0;
  // });

  // --------------------------------- THIS CODE IS ADDING MISSING DATES AND SORTING DATA ENDS ---------------------------------

  // ------------------------------------------------------ SKIPPING LABELS ------------------------------------------------------
  // const valuesToShow = graphData.map((v, i) => i % 2 === 0 ? "" : v["DumpTableMain.eventTime"]);
  // ------------------------------------------------------ SKIPPING LABELS ------------------------------------------------------

  React.useEffect(() => {
    HTMLCanvasElement.prototype.getBBox = function () {
      return { width: this.offsetWidth, height: this.offsetHeight };
    };
  }, []);

  const CommonProps = {
    theme: {
      axis: {
        bottom: {
          ticks: {
            text: {
              fontSize: 24, // Adjust this value for the x-axis font size
            },
          },
        },
      },
    },
    data: graphData,
    pixelRatio: 1,
    innerPadding: 0,
    minValue: minValue !== undefined ? minValue : "auto",
    maxValue: maxValue !== undefined ? maxValue : "auto",
    reverse: false,
    borderWidth: 0,
    borderRadius: borderRadius ? borderRadius : 0,
    enableGridX: enableGridX !== undefined ? enableGridX : false,
    enableGridY: enableGridY !== undefined ? enableGridY : true,
    isInteractive: isInteractive ?? true,
    keys: groupByGraphKeys?.length > 0 ? groupByGraphKeys : ["count"],
    indexBy: indexBy,
    margin: margin ? margin : { top: 5, right: 80, bottom: 110, left: 60 },
    padding: padding ? padding : 0.5,
    valueScale: { type: "linear" },
    indexScale: { type: "band", round: true },
    colors: colors ? colors : { scheme: "set3" },
    enableLabel: false,
    tooltip: ({ id, value, color, indexValue }) => (
      <span
        className="d-flex"
        style={{
          background: "white",
          color: "inherit",
          fontSize: "inherit",
          borderRadius: "2px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
          padding: "5px 9px",
        }}
      >
        {graphName}: {getToolTipNames(id)}: {value}
      </span>
    ),
    borderColor: {
      from: "color",
      modifiers: [["darker", 1.6]],
    },
    axisTop: null,
    axisRight: null,
    axisBottom:
      isAxisBottomDisplay !== undefined
        ? isAxisBottomDisplay
        : {
            tickSize: 0,
            // tickPadding: 5,
            tickRotation: 47,
            legendPosition: "middle",
            // legendOffset: 165,
            ...(useCanvas && {
              renderTick: ({ x, y, textAnchor, textX, textY, value }) => (
                <g transform={`translate(${x},${y})`}>
                  <text
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY + 5})`} // places label 5px lower than origin place
                    style={{ fontSize: "28px" }}
                  >
                    {value}
                  </text>
                </g>
              ),
            }),
            format: (value) => {
              return selectedGranularity
                ? getBarGraphValues(
                    value,
                    getGraphGranularityMappings(
                      selectedGranularity?.toLowerCase(),
                    ),
                    indexBy,
                    matchingUsersInGraphData,
                  )
                : mapEmojis
                  ? getEmojisMapping(value)
                  : value;
            },
          },
    axisLeft:
      isAxisLeftDisplay !== undefined
        ? isAxisLeftDisplay
        : {
            tickSize: 0,
            tickPadding: 8,
            tickRotation: 0,
            legend: graphName,
            legendPosition: "middle",
            legendOffset: -50,
          },
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: {
      from: "color",
      modifiers: [["darker"]],
    },
    legends: [],
    role: "application",
    ariaLabel: "Nivo bar chart demo",
  };

  return (
    <div
      style={{ height: graphContainerHeight ? graphContainerHeight : "500px" }}
    >
      {console.log("graphDatagraphData", graphData)}
      {useCanvas ? (
        <ResponsiveBarCanvas {...CommonProps} />
      ) : (
        <ResponsiveBar {...CommonProps} />
      )}
    </div>
  );
};

export default BarGraph;
